import React from 'react'
import AccountStore from '../stores/AccountStore'
import RecipesStore from '../stores/RecipesStore'
import IngredientsStore from '../stores/IngredientsStore'
import ToolsStore from '../stores/ToolsStore'

export const stores = {
  accountStore: new AccountStore(),
  //Don't change order above
  recipesStore: new RecipesStore(),
  ingredientsStore: new IngredientsStore(),
  toolsStore: new ToolsStore(),
}

export default React.createContext(stores)
