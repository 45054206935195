import { Route, Redirect } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { flask, newspaper, cog, hammer } from 'ionicons/icons';
import Login from './pages/Login';
import Recipes from './pages/Recipes';
import Recipe from './pages/Recipe';
import Ingredients from './pages/Ingredients';
import Ingredient from './pages/Ingredient';
import Tools from './pages/Tools';
import Tool from './pages/Tool';
import Settings from './pages/Settings';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css'

import './App.css'

import { observer, Observer } from 'mobx-react-lite';

import useStores from './hooks/useStores'

const App = observer(() => {

  const {accountStore} = useStores();
  const initializing = accountStore.isLoggedIn === null;

  return (
    <IonApp>
      <div id="splashscreen" className={`${initializing?'show':'hide'}`}></div>
      {!initializing &&
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Observer>
                {()=> {
                  if(!accountStore.isLoggedIn){
                    return (
                    <>
                      <Route path="/">
                        <Login />
                      </Route>
                    </>
                    )
                  } else {
                    return(
                      <>
                        <Route exact path="/recipes">
                          <Recipes />
                        </Route>
                        <Route exact path="/recipes/:id">
                          <Recipe />
                        </Route>
                        <Route exact path="/ingredients">
                          <Ingredients />
                        </Route>
                        <Route exact path="/ingredients/:id">
                          <Ingredient />
                        </Route>
                        <Route exact path="/tools">
                          <Tools />
                        </Route>
                        <Route exact path="/tools/:id">
                          <Tool />
                        </Route>
                        <Route path="/profile">
                          <Settings />
                        </Route>
                        <Route exact path="/">
                          <Redirect to="/recipes" />
                        </Route>
                      </>
                    )
                  }
                }}
              </Observer>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="recipes" href="/recipes" disabled={!accountStore.isLoggedIn}>
                <IonIcon icon={newspaper} style={{transform:"scale(0.8)"}}/>
              </IonTabButton>
              <IonTabButton tab="ingredients" href="/ingredients" disabled={!accountStore.isLoggedIn}>
                <IonIcon icon={flask} style={{transform:"scale(0.8)"}}/>
              </IonTabButton>
              <IonTabButton tab="tools" href="/tools" disabled={!accountStore.isLoggedIn}>
                <IonIcon icon={hammer} style={{transform:"scale(0.8)"}}/>
              </IonTabButton>
              <IonTabButton tab="profile" href="/profile" disabled={!accountStore.isLoggedIn}>
                <IonIcon icon={cog} style={{transform:"scale(0.8)"}}/>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      }
    </IonApp> 
  )
})

export default App
