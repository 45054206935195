import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, 
         IonBackButton, IonButtons, IonButton, IonIcon, 
         IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, 
         IonChip, IonLabel, IonCardTitle, IonList, IonItem, IonToggle} from '@ionic/react'
import { refresh, hourglass, stopwatch, timer, balloon, rocket, diamond, people, barbell, flame, wallet } from 'ionicons/icons';
import { observer } from 'mobx-react-lite'
import useStores from '../hooks/useStores'
import {useParams} from 'react-router-dom'
import styles from './Recipe.module.css'
import { PDFDownloadLink } from '@react-pdf/renderer'

import IngredientsCard from './Recipe/IngredientsCard'
import ToolsCard from './Recipe/ToolsCard'
import Methods from './Recipe/Methods'
import PDFExport from './Recipe/PDFExport';
import { useState } from 'react';

const Recipe = observer( ()=> {
  const { recipesStore, ingredientsStore, toolsStore } = useStores()
  const { id } = useParams()
  const [exportPDF, setExportPDF] = useState(false);

  const recipe = recipesStore.getRecipe(id)

  const doRefresh = function(e){
    recipesStore.fetchRecipes()
    ingredientsStore.fetchIngredients()
    toolsStore.fetchTools()
    recipesStore.resetMethodCheck(id)
    recipesStore.resetMethodPartHide(id)
  }

  const toggleInformations = async function(e){
    recipesStore.toggleInformations()
  }

  let hoursSalary,priceIngredients,priceTools,totalWeight,weightPerServing,totalKCal,kCalPerServing,priceWork,sellPrice,servingPrice;

  if(recipesStore.displayInformations){
    hoursSalary = 20
    priceIngredients = recipesStore.getRecipeTotalIngredientsPrice(id)
    priceTools = recipesStore.getRecipeTotalToolsPrice(id)
    totalWeight = recipesStore.getRecipeTotalWeight(id)
    weightPerServing = totalWeight / (recipesStore.getRecipe(id)?.acf?.servings || 1)
    totalKCal = recipesStore.getRecipeTotalKCal(id)
    kCalPerServing = totalKCal / (recipesStore.getRecipe(id)?.acf?.servings || 1)
    priceWork = (recipesStore.getRecipe(id)?.acf?.cooking_time || 0) / 60 * hoursSalary
    sellPrice = priceWork*1 + priceIngredients*1 + priceTools*1
    servingPrice = sellPrice / (recipesStore.getRecipe(id)?.acf?.servings || 1)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonBackButton slot={"start"} />
          <IonTitle>{recipe?.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doRefresh}>
              <IonIcon slot="icon-only" icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <div style={{
            width:"100%",
            height:"0",
            paddingTop:"100%",
            position:"relative",
            overflow:"hidden",
            backgroundImage:"url("+recipe?.acf?.cover_image+")",
            backgroundSize:"cover",
            backgroundPosition:"center center"
          }}>
          </div>

          <IonCardHeader>
            <IonCardSubtitle>{recipe?.title}</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            {recipe?.acf?.description}
            <br/><br/>
            <IonChip>
              <IonIcon icon={stopwatch} color="primary" />
              <IonLabel>Préparation : {recipe?.acf?.cooking_time} minutes</IonLabel>
            </IonChip><br/>
            <IonChip>
              <IonIcon icon={timer} color="primary" />
              <IonLabel>Cuisson : {recipe?.acf?.baking_time} minutes</IonLabel>
            </IonChip><br/>
            <IonChip>
              <IonIcon icon={hourglass} color="primary" />
              <IonLabel>Repos : {recipe?.acf?.rest_time} minutes</IonLabel>
            </IonChip>
            <br/><br/>

            <IonChip>
              {recipe?.acf?.difficulty === 'easy' && <IonIcon icon={balloon} color="primary" />}
              {recipe?.acf?.difficulty === 'medium' && <IonIcon icon={diamond} color="primary" />}
              {recipe?.acf?.difficulty === 'hard' && <IonIcon icon={rocket} color="primary" />}
              <IonLabel>
                Difficulté : {recipe?.acf?.difficulty === 'easy' ? 'Facile': recipe?.acf?.difficulty === 'medium' ? 'Intermédiaire': 'Difficile'}
              </IonLabel>
            </IonChip>
            <br/><br/>

            <IonChip>
              <IonIcon icon={people} color="primary" />
              <IonLabel>Parts : {recipe?.acf?.servings}</IonLabel>
            </IonChip>
          </IonCardContent>
        </IonCard>

        <IonCard>

          <IonCardHeader>
            <IonToggle
              color="primary" 
              class="ion-float-right" 
              style={{transform:"scale(0.6)"}}
              checked={recipesStore.displayInformations}
              onClick={toggleInformations}
            />
            <IonCardTitle>Informations</IonCardTitle>
          </IonCardHeader>

          {recipesStore.displayInformations && <IonCardContent>

            <IonChip style={{width:"94%"}}>
              <IonIcon icon={barbell} color="primary" />
              <IonLabel>Poids</IonLabel>
            </IonChip>
            <IonList>
              <IonItem className={styles.information}>
                <IonLabel>Poids des ingrédients</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {totalWeight} g
                </IonLabel>
              </IonItem>
              <IonItem className={styles.information}>
                <IonLabel>Poids par part</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {weightPerServing.toFixed(0)} g
                </IonLabel>
              </IonItem>
            </IonList>

            <IonChip style={{width:"94%"}}>
              <IonIcon icon={flame} color="primary" />
              <IonLabel>Apport</IonLabel>
            </IonChip>
            <IonList>
              <IonItem className={styles.information}>
                <IonLabel>Apport des ingrédients</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {totalKCal} kCal
                </IonLabel>
              </IonItem>
              <IonItem className={styles.information}>
                <IonLabel>Apport par part</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {kCalPerServing.toFixed(0)} kCal
                </IonLabel>
              </IonItem>
            </IonList>

            <IonChip style={{width:"94%"}}>
              <IonIcon icon={wallet} color="primary" />
              <IonLabel>Prix</IonLabel>
            </IonChip>
            <IonList>
              <IonItem className={styles.information}>
                <IonLabel>Prix des ingrédients</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {priceIngredients} €
                </IonLabel>
              </IonItem>
              <IonItem className={styles.information}>
                <IonLabel>Prix des ustensiles</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {priceTools} €
                </IonLabel>
              </IonItem>
              <IonItem className={styles.information}>
                <IonLabel>Prix main d'oeuvre</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {priceWork.toFixed(2)} €
                </IonLabel>
              </IonItem>
              <IonItem className={styles.information}>
                <IonLabel>Prix total</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {sellPrice.toFixed(2)} €
                </IonLabel>
              </IonItem>
              <IonItem className={styles.information}>
                <IonLabel>Prix de la part</IonLabel>
                <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                  {servingPrice.toFixed(2)} €
                </IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>}

        </IonCard>

        <IngredientsCard id={id}/>
        <ToolsCard id={id}/>
        <Methods id={id}/>

        <IonButton expand="block" style={{margin:"0 15px 30px"}} onClick={() => {if(!exportPDF) setExportPDF(true)}}>
          {!exportPDF ? "Generate PDF":
            <PDFDownloadLink document={<PDFExport id={id}/>} fileName={recipe?.title+".pdf"} style={{color:"white"}}>
              {({ blob, url, loading, error }) =>
                loading ? 'Generating pdf...' : 'Export as PDF'
              }
            </PDFDownloadLink>
          }
        </IonButton>
      </IonContent>
    </IonPage>
  )
} )

export default Recipe
