import { Fragment } from 'react'
import { IonImg, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, 
         IonLabel, IonCardTitle, IonList, IonItemSliding, IonItem, 
         IonItemOptions, IonItemOption, IonAvatar, IonToggle, IonSegment, IonSegmentButton } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '../../hooks/useStores'
import styles from './IngredientsCard.module.css'
import { useHistory } from 'react-router'

const IngredientsCard = observer( ({id})=> {
  const { recipesStore, ingredientsStore } = useStores()

  const recipe = recipesStore.getRecipe(id)

  const history = useHistory()

  const toggleIngredients = async function(e){
    recipesStore.toggleIngredients()
  }
  const changeIngredientsListData = e => {
    recipesStore.setIngredientsListData(e.detail.value)
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonToggle 
          color="primary" 
          class="ion-float-right" 
          style={{transform:"scale(0.6)"}}
          checked={recipesStore.displayIngredients}
          onClick={toggleIngredients}
        />
        <IonCardTitle>
          <IonLabel>Ingrédients</IonLabel>
        </IonCardTitle>
      </IonCardHeader>

      {recipesStore.displayIngredients && 
        <>
          {recipe?.acf?.parts.map((part, index) => {
            if(!part.ingredients.length) 
              return null
            return (
              <Fragment key={index}>
                <IonCardHeader>
                  <IonCardSubtitle>{part.title}</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonList>
                    {part?.ingredients?.map((ingredientItem, index) => {
                      let ingredient = ingredientsStore.getIngredient(ingredientItem.ingredient)
                      let priceIngredient = (ingredient?.acf?.price_per_gram || 0) * (ingredientItem?.quantity || 0)
                      let kcalIngredient = (ingredient?.acf?.k_cal_100g || 0) * (ingredientItem?.quantity/100 || 0)
                      return (
                        <IonItemSliding key={index} className={styles.ingredient}>

                          <IonItemOptions side="start">
                            <IonItemOption color="secondary" onClick={()=>{ history.push(`/ingredients/${ingredient.id}`) }}>Détails</IonItemOption>
                          </IonItemOptions>

                          <IonItem>
                            <IonAvatar slot="start">
                              <IonImg src={ingredient?.acf?.cover_image} style={{background:"white"}}/>
                            </IonAvatar>
                            <IonLabel>{ingredient?.title}</IonLabel>
                            <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                              {recipesStore.ingredientsListData === 'weight'? ingredientItem?.quantity+" g"
                              :recipesStore.ingredientsListData === 'price'? priceIngredient.toFixed(2)+" €"
                              :recipesStore.ingredientsListData === 'kcal'? kcalIngredient.toFixed(0)+" kCal"
                              :""}
                            </IonLabel>
                          </IonItem>

                          <IonItemOptions side="end">
                            <IonItemOption color="primary" onClick={()=>alert('Coming soon...')}>À acheter</IonItemOption>
                          </IonItemOptions>

                        </IonItemSliding>
                      )
                    })}
                  </IonList>
                </IonCardContent>
              </Fragment>
            )
          })}

          <IonCardContent>
            <IonList>
              <IonItemSliding className={styles.ingredient}>
                <IonItem>
                  <IonSegment value={recipesStore.ingredientsListData} onIonChange={changeIngredientsListData}>
                    <IonSegmentButton value="weight">Poids</IonSegmentButton>
                    <IonSegmentButton value="kcal">Apport</IonSegmentButton>
                    <IonSegmentButton value="price">Prix</IonSegmentButton>
                  </IonSegment>
                </IonItem>
              </IonItemSliding>
            </IonList>
          </IonCardContent>
        </>
      }
    </IonCard>
  )
} )

export default IngredientsCard
