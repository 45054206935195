import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import API from './API';

class IngredientsStore {
  @observable _ingredients = new Map()
  @observable status = {
    loading: false,
    loaded: false
  }

  constructor(){
    makeObservable(this)
  }

  @action async fetchIngredients(page = 1){
    this.status.loading = true
    try {
      let response = await API.axios.get(API.endpoints.ingredients(), {params:{page: page, per_page: 100}})
      runInAction(()=>{
        response.data.length && response.data.forEach(ingredient => {
          this._ingredients.set(ingredient.id, ingredient)
        });
        this.status.loaded = true
        this.status.loading = false
      })
      if(page < response.headers?.['x-wp-totalpages']){
        this.fetchIngredients(page+1)
      }
    } catch(e){
      console.log('Could not fetch ingredients', e)
    }
  }

  @computed get ingredients(){
    if(!this.status.loaded && !this.status.loading)
      this.fetchIngredients()
    return this._ingredients
  }
  @computed get orderedIngredients(){
    if(!this.status.loaded && !this.status.loading)
      this.fetchIngredients()
    const ingredients = Array.from(this._ingredients.values())
    ingredients.sort((a,b) => a.title<b.title?-1:1)
    return ingredients
  }

  getIngredient(id){
    id = parseInt(id)
    return this.ingredients.get(id)
  }
}

export default IngredientsStore