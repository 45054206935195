import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import API from './API';

class ToolsStore {
  @observable _tools = new Map()
  @observable status = {
    loading: false,
    loaded: false
  }

  constructor(){
    makeObservable(this)
  }

  @action async fetchTools(page = 1){
    this.status.loading = true
    try {
      let response = await API.axios.get(API.endpoints.tools(), {params:{page: page, per_page: 100}})
      runInAction(()=>{
        response.data.length && response.data.forEach(tool => {
          this._tools.set(tool.id, tool)
        });
        this.status.loaded = true
        this.status.loading = false
      })
      if(page < response.headers?.['x-wp-totalpages']){
        this.fetchTools(page+1)
      }
    } catch(e){
      console.log('Could not fetch tools', e)
    }
  }

  @computed get tools(){
    if(!this.status.loaded && !this.status.loading)
      this.fetchTools()
    return this._tools
  }
  @computed get orderedTools(){
    if(!this.status.loaded && !this.status.loading)
      this.fetchTools()
    const tools = Array.from(this._tools.values())
    tools.sort((a,b) => a.title<b.title?-1:1)
    return tools
  }

  getTool(id){
    id = parseInt(id)
    return this.tools.get(id)
  }
}

export default ToolsStore