import { IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, 
         IonToolbar, IonLabel, IonButtons, IonButton, IonIcon, IonItemDivider } from '@ionic/react'
import { refresh } from 'ionicons/icons'
import { observer } from 'mobx-react-lite'
import useStores from '../hooks/useStores'
import {useHistory} from 'react-router-dom'
import { Fragment } from 'react'

const Recipes = observer( ()=> {
  const { recipesStore } = useStores()

  const doRefresh = async function(e){
    await recipesStore.fetchRecipes()
  }

  const history = useHistory()

  let previousLetter = ""

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Recipes</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doRefresh}>
              <IonIcon slot="icon-only" icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Recipes</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          {recipesStore.orderedRecipes.map(item => {
            let displayDivider = item?.title?.charAt?.(0) !== previousLetter
            previousLetter = item?.title?.charAt?.(0)
            return (
              <Fragment key={item.id}>
                {displayDivider && <IonItemDivider sticky={true}>
                  <IonLabel>
                    {previousLetter}
                  </IonLabel>
                </IonItemDivider>}
                <IonItem button={true} onClick={()=>{history.push(`/recipes/${item.id}`)}}>
                  <IonLabel className="ion-padding-start">{item?.title}</IonLabel>
                </IonItem>
              </Fragment>
            )}
          )}
        </IonList>
      </IonContent>
    </IonPage>
  )
} )

export default Recipes
