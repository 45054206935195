import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, 
         IonBackButton, IonButtons, IonButton, IonIcon, 
         IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, 
         IonChip, IonLabel} from '@ionic/react'
import { refresh, wallet, batteryFull, cash } from 'ionicons/icons';
import { observer } from 'mobx-react-lite'
import useStores from '../hooks/useStores'
import {useParams} from 'react-router-dom'

const Tool = observer( ()=> {
  const { toolsStore } = useStores()
  const { id } = useParams()

  const tool = toolsStore.getTool(id)

  const doRefresh = function(e){
    toolsStore.fetchTools()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonBackButton slot={"start"} />
          <IonTitle>{tool?.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doRefresh}>
              <IonIcon slot="icon-only" icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <div style={{
            width:"100%",
            height:"0",
            paddingTop:"100%",
            position:"relative",
            overflow:"hidden",
            backgroundImage:"url("+tool?.acf?.cover_image+")",
            backgroundSize:"cover",
            backgroundPosition:"center center"
          }}>
          </div>

          <IonCardHeader>
            <IonCardSubtitle>{tool?.title}</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            {tool?.acf?.description}
            {tool?.acf?.description && <><br/><br/></>}
            <IonChip>
              <IonIcon icon={wallet} color="primary" />
              <IonLabel>Prix : {tool?.acf?.price} €</IonLabel>
            </IonChip><br/>
            <IonChip>
              <IonIcon icon={batteryFull} color="primary" />
              <IonLabel>Utilisations Max : {(tool?.acf?.number_of_uses)}</IonLabel>
            </IonChip><br/>
            <IonChip>
              <IonIcon icon={cash} color="primary" />
              <IonLabel>Prix par utilisation : {(tool?.acf?.price || 0)/(tool?.acf?.number_of_uses || 1)} €</IonLabel>
            </IonChip>
          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  )
} )

export default Tool
