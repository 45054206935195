import { makeObservable, observable, action } from 'mobx'
import API from './API'

class AccountStore {
  @observable isLoggedIn = false
  @observable isLoggingIn = false
  @observable form = {}
  @observable userInfo = {}

  @observable loginFailed = true

  constructor(){
    makeObservable(this)

    let userInfoFromLS = localStorage.getItem('userInfo')
    if(userInfoFromLS){
      try{
        this.userInfo = JSON.parse(userInfoFromLS)
        this.isLoggedIn = true
        API.setToken(this.userInfo.token)
      } catch (e) {
        console.log('Could not parse local storage infos')
      }
    }
  }

  @action
  async signIn(){
    this.isLoggingIn = true
    this.loginFailed = false
    try{
      let response = await API.rawAxios.post(API.endpoints.login(), {
        'username':this.form?.login?.username,
        'password':this.form?.login?.password
      })
      this.userInfo = response.data
      localStorage.setItem('userInfo', JSON.stringify(response.data))
      API.setToken(this.userInfo.token)
      this.isLoggingIn = false
      this.isLoggedIn = true
    } catch(e){
      this.loginFailed = true
    }
  }

  @action
  signOut(){
    this.isLoggedIn = false
    this.jwt = null
    this.userInfo = {}
    localStorage.removeItem('userInfo')
  }

  @action setFormValue(form, item, value){
    this.form[form] = this.form[form] || {}
    this.form[form][item] = value
  }

  @action getFormValue(form, item){
    return this.form?.[form]?.[item]
  }
}

export default AccountStore