import { Document, Image, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import useStores from '../../hooks/useStores'

Font.register({
  family: 'OpenSans-SemiBold',
  src: '/assets/pdf-fonts/OpenSans-SemiBold.ttf'
});
Font.register({
  family: 'Poppins-Light',
  src: '/assets/pdf-fonts/Poppins-Light.ttf'
});
Font.register({
  family: 'Poppins-Regular',
  src: '/assets/pdf-fonts/Poppins-Regular.ttf'
});

const styles = StyleSheet.create({
  page:{
    padding:"1.5cm",
    fontSize: "2.4mm",
    fontFamily: 'Poppins-Light'
  },
  header:{
    flexDirection:"row",
  },
  overview:{
    width:"12cm",
    paddingRight:"0.75cm"
  },
  description:{
    fontFamily: 'Poppins-Light'
  },
  recipeImage:{
    width:"6cm",
    height:"6cm",
    marginTop:"1.2cm"
  },
  recipeTitle:{
    fontSize:"5mm",
    marginBottom:"0.3cm",
    fontFamily: 'Poppins-Regular'
  },
  sectionTitle:{
    marginTop:"0.75cm",
    fontSize:"3.5mm",
    fontFamily: 'Poppins-Regular',
    textDecoration:"underline"
  },
  listItem: {
    flexDirection:"row",
    paddingTop:"0.75mm",
    paddingBottom:"0.75mm",
    paddingLeft:"0.75mm",
    paddingRight:"0.75mm",
    alignItems:"center"
  },
  listItemOdd: {
    backgroundColor:"#efefef",
  },
  partTitle: {
    fontSize:"3mm",
    marginBottom:"0.3cm",
    marginTop:"0.3cm",
    textTransform:"uppercase",
    letterSpacing:"1px",
    fontFamily:'OpenSans-SemiBold'
  },
  part: {
    flexDirection:"row"
  },
  partList: {
    marginBottom:"0.5cm",
    width:"9cm"
  },
  partListLeft: {
    paddingRight:"1mm"
  },
  partListRight: {
    paddingLeft:"1mm"
  },
  itemName: {
    width:"5cm",
    color:"#333333",
  },
  itemImageWrapper: {
    width:"0.8cm",
    height:"0.7cm",
    borderRadius:"0.4cm",
    overflow:"hidden",
    padding:0,
    marginRight:"0.25cm",
  },
  itemImage: {
    width:"0.8cm",
    height:"0.8cm",
    margin:0
  },
  itemQty: {
    width:"3cm",
    textAlign:"right",
    color:"#333333",
    paddingRight:"1mm"
  },
  tag:{
    backgroundColor:'#efefef',
    borderRadius:"4mm",
    padding:"2mm 4mm",
    marginTop:"3mm",
    width:"auto"
  },
  methodParagraph:{
    marginBottom:"0.5cm",
    fontSize:"3mm"
  },
  methodPart:{
    flexDirection:"row"
  },
  methodTexts:{
    width:"11cm",
    marginRight:"1cm"
  },
  methodImages:{
    width:"6cm",
  },
  methodImage:{
    width:"6cm",
    height:"6cm"
  }
});

const PDFExport = observer(({id}) => {
  const { recipesStore, ingredientsStore, toolsStore } = useStores()
  const recipe = recipesStore.getRecipe(id)

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.header}>
            <View style={styles.overview}>
              <Text style={styles.recipeTitle}>{recipe?.title}</Text>
              <Text style={styles.description}>{recipe?.acf?.description}</Text>

                <Text style={styles.tag}>Préparation : {recipe?.acf?.cooking_time} minutes</Text>
                <Text style={styles.tag}>Cuisson : {recipe?.acf?.baking_time} minutes</Text>
                <Text style={styles.tag}>Repos : {recipe?.acf?.rest_time} minutes</Text>
                <Text style={styles.tag}>
                  Difficulté : {recipe?.acf?.difficulty === 'easy' ? 'Facile': recipe?.acf?.difficulty === 'medium' ? 'Intermédiaire': 'Difficile'}
                </Text>
                <Text style={styles.tag}>Parts : {recipe?.acf?.servings}</Text>
            </View>
            <Image src={recipe?.acf?.cover_image+"?pdf"} style={styles.recipeImage}/>
          </View>


          <Text style={styles.sectionTitle}>Ingrédients &amp; Ustensiles</Text>
          {recipe?.acf?.parts.map((part, index) => {
            if(!part.ingredients.length && !part.ingredients.length) 
              return null
            return (
              <Fragment key={index}>
                <Text style={styles.partTitle}>{part.title}</Text>
                <View style={styles.part}>
                  <View style={[styles.partList,styles.partListLeft]}>
                    {part?.ingredients?.map?.((ingredientItem, index) => {
                      let ingredient = ingredientsStore.getIngredient(ingredientItem.ingredient)
                      return (
                        <View key={index} style={[styles.listItem, index%2===1?styles.listItemOdd:null]}>
                          <View style={styles.itemImageWrapper} >
                            <Image style={styles.itemImage} src={ingredient?.acf?.cover_image+"?pdf"}/>
                          </View>
                          <Text style={styles.itemName}>{ingredient?.title}</Text>
                          <Text style={styles.itemQty}>{ingredientItem?.quantity+" g"}</Text>
                        </View>
                      )
                    })}
                  </View>
                  <View style={[styles.partList,styles.partListRight]}>
                    {part?.tools?.map?.((toolItem, index) => {
                      let tool = toolsStore.getTool(toolItem.tool)
                      return (
                        <View key={index} style={[styles.listItem, index%2===1?styles.listItemOdd:null]}>
                          <View style={styles.itemImageWrapper} >
                            <Image style={styles.itemImage} src={tool?.acf?.cover_image+"?pdf"}/>
                          </View>
                          <Text style={styles.itemName}>{tool?.title}</Text>
                          <Text style={styles.itemQty}>{toolItem?.quantity}</Text>
                        </View>
                      )
                    })}
                  </View>
                </View>
              </Fragment>
            )
          })}


          <Text style={styles.sectionTitle}>Préparation</Text>
          {recipe?.acf?.parts?.map?.((part, partIndex) => {
            if(!part?.method?.length)
              return null
            return (
              <View key={partIndex}>
                <Text style={styles.partTitle}>{part.title}</Text>

                <View style={styles.methodPart}>
                  <View style={styles.methodTexts}>
                    {!recipesStore.isMethodPartHidden(id, partIndex) && part?.method?.map?.((block, blockIndex) => {
                      switch(block?.acf_fc_layout){
                        case 'paragraph':
                          return (
                            <Text style={styles.methodParagraph} key={blockIndex}>{block?.text}</Text>
                          )
                        default:
                          return null
                      }
                    })} 
                  </View>
                  <View style={styles.methodImages}>
                    {!recipesStore.isMethodPartHidden(id, partIndex) && part?.method?.map?.((block, blockIndex) => {
                      switch(block?.acf_fc_layout){
                        case 'picture':
                          return (
                            <Image style={styles.methodImage} key={blockIndex} src={block.image}/>
                          )
                        default:
                          return null
                      }
                    })} 
                  </View>
                </View>     
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
});

export default PDFExport