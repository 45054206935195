import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react'
import useStores from '../hooks/useStores'

import styles from './Login.module.css'

const Login = () => {
  const {accountStore} = useStores();

  const usernameChanged = e => {
    accountStore.setFormValue('login', 'username', e.detail.value)
  }
  const passwordChanged = e => {
    accountStore.setFormValue('login', 'password', e.detail.value)
  }
  const doLogin = () => {
    accountStore.signIn()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="padded">
        <IonItem>
          <IonLabel position="floating">Nom d'utilisateur</IonLabel>
          <IonInput value={accountStore.getFormValue('login', 'username')} onIonChange={usernameChanged}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Mot de passe</IonLabel>
          <IonInput type="password" value={accountStore.getFormValue('login', 'password')} onIonChange={passwordChanged}></IonInput>
        </IonItem>

        <IonButton expand="block" className={styles.loginButton} onClick={doLogin}>Se connecter</IonButton>
      </IonContent>
    </IonPage>
  )
}

export default Login
