import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '../hooks/useStores'

const Settings = observer( ()=> {
  const { accountStore } = useStores()

  const doSignOut = async function(e){
    accountStore.signOut()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Paramètres</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Paramètres</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonButton expand="block" onClick={doSignOut}>Sign out</IonButton>

      </IonContent>
    </IonPage>
  )
} )

export default Settings
