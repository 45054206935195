import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import API from './API'
import {stores} from '../contexts/storesContext'

class RecipesStore {
  @observable _recipes = new Map()
  @observable status = {
    loading: false,
    loaded: false
  }
  @observable ingredientsListData = "weight"
  @observable toolsListData = "qty"
  @observable displayInformations = false
  @observable displayIngredients = true
  @observable displayTools = true
  @observable methodChecker = new Map()
  @observable methodPartHide = new Map()
  @observable displayPartsInfoModal = false

  constructor(){
    makeObservable(this)
  }

  @action async fetchRecipes(page = 1){
    this.status.loading = true
    try {
      let response = await API.axios.get(API.endpoints.recipes(), {params:{page: page, per_page: 100}})
      runInAction(()=>{
        response.data.length && response.data.forEach(recipe => {
          this._recipes.set(recipe.id, recipe)
        });
        this.status.loaded = true
        this.status.loading = false
      })
      if(page < response.headers?.['x-wp-totalpages']){
        this.fetchIngredients(page+1)
      }
    } catch(e){
      console.log('Could not fetch recipes', e)
    }
  }

  @computed get recipes(){
    if(!this.status.loaded && !this.status.loading)
      this.fetchRecipes()
    return this._recipes
  }
  @computed get orderedRecipes(){
    if(!this.status.loaded && !this.status.loading)
      this.fetchRecipes()
    const recipes = Array.from(this._recipes.values())
    recipes.sort((a,b) => a.title<b.title?-1:1)
    return recipes
  }

  getRecipe(id){
    id = parseInt(id)
    return this.recipes.get(id)
  }

  @action
  toggleInformations(){
    this.displayInformations = !this.displayInformations
  }

  @action
  toggleIngredients(){
    this.displayIngredients = !this.displayIngredients
  }

  @action
  toggleTools(){
    this.displayTools = !this.displayTools
  }

  @action
  toggleMethodCheck(recipe, part, block){
    const key = `r${recipe}p${part}b${block}`
    this.methodChecker.set(key, !this.methodChecker.get(key))
  }

  @action
  resetMethodCheck(recipe){
    if(recipe){
      Array.from(this.methodChecker.keys()).forEach(key => {
        const regexpSearch = new RegExp(`^r${recipe}p`,'gi')
        if(regexpSearch.test(key)){
          this.methodChecker.delete(key)
        }
      });
    } else {
      this.methodChecker = new Map()
    }
  }

  isMethodChecked(recipe, part, block){
    const key = `r${recipe}p${part}b${block}`
    return !!this.methodChecker.get(key)
  }

  @action
  toggleMethodPart(recipe, part){
    const key = `r${recipe}p${part}`
    this.methodPartHide.set(key, !this.methodPartHide.get(key))
  }

  isMethodPartHidden(recipe, part){
    const key = `r${recipe}p${part}`
    return !!this.methodPartHide.get(key)
  }

  @action
  resetMethodPartHide(recipe){
    if(recipe){
      Array.from(this.methodPartHide.keys()).forEach(key => {
        const regexpSearch = new RegExp(`^r${recipe}p`,'gi')
        if(regexpSearch.test(key)){
          this.methodPartHide.delete(key)
        }
      });
    } else {
      this.methodPartHide = new Map()
    }
  }

  @action
  setIngredientsListData(criteria){
    this.ingredientsListData = criteria
  }

  @action
  setToolsListData(criteria){
    this.toolsListData = criteria
  }

  getRecipeTotalWeight(id){
    let totalWeight = 0
    const recipe = this.getRecipe(id)
    recipe?.acf?.parts?.forEach?.(part => {
      part?.ingredients?.forEach?.(ingredientItem => {
        totalWeight += 1*(ingredientItem?.quantity || 0)
      })
    })
    return totalWeight
  }

  getRecipeTotalIngredientsPrice(id){
    let totalPrice = 0
    const recipe = this.getRecipe(id)
    recipe?.acf?.parts?.forEach?.(part => {
      part?.ingredients?.forEach?.(ingredientItem => {
        const ingredient = stores.ingredientsStore.getIngredient(ingredientItem.ingredient)
        totalPrice += (ingredientItem?.quantity || 0) * (ingredient?.acf?.price_per_gram || 0)
      })
    })
    return totalPrice.toFixed(2)
  }

  getRecipeTotalToolsPrice(id){
    let totalPrice = 0
    const recipe = this.getRecipe(id)
    recipe?.acf?.parts?.forEach?.(part => {
      part?.tools?.forEach?.(toolItem => {
        const tool = stores.toolsStore.getTool(toolItem.tool)
        totalPrice += (toolItem?.quantity || 0) * ((tool?.acf?.price || 0)/(tool?.acf?.number_of_uses || 1))
      })
    })
    return totalPrice.toFixed(2)
  }

  getRecipeTotalKCal(id){
    let totalKCal = 0
    const recipe = this.getRecipe(id)
    recipe?.acf?.parts?.forEach?.(part => {
      part?.ingredients?.forEach?.(ingredientItem => {
        const ingredient = stores.ingredientsStore.getIngredient(ingredientItem.ingredient)
        totalKCal += (ingredientItem?.quantity/100 || 0) * (ingredient?.acf?.k_cal_100g || 0)
      })
    })
    return totalKCal.toFixed(0)
  }
}

export default RecipesStore