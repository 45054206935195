import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, 
         IonBackButton, IonButtons, IonButton, IonIcon, 
         IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, 
         IonChip, IonLabel} from '@ionic/react'
import { refresh, flame, wallet } from 'ionicons/icons';
import { observer } from 'mobx-react-lite'
import useStores from '../hooks/useStores'
import {useParams} from 'react-router-dom'

const Ingredient = observer( ()=> {
  const { ingredientsStore } = useStores()
  const { id } = useParams()

  const ingredient = ingredientsStore.getIngredient(id)

  const doRefresh = function(e){
    ingredientsStore.fetchIngredients()
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonBackButton slot={"start"} />
          <IonTitle>{ingredient?.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={doRefresh}>
              <IonIcon slot="icon-only" icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <div style={{
            width:"100%",
            height:"0",
            paddingTop:"100%",
            position:"relative",
            overflow:"hidden",
            backgroundImage:"url("+ingredient?.acf?.cover_image+")",
            backgroundSize:"cover",
            backgroundPosition:"center center"
          }}>
          </div>

          <IonCardHeader>
            <IonCardSubtitle>{ingredient?.title}</IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            {ingredient?.acf?.description}
            {ingredient?.acf?.description && <><br/><br/></>}
            <IonChip>
              <IonIcon icon={wallet} color="primary" />
              <IonLabel>Prix au gramme : {ingredient?.acf?.price_per_gram} €</IonLabel>
            </IonChip><br/>
            <IonChip>
              <IonIcon icon={flame} color="primary" />
              <IonLabel>Apport : {(ingredient?.acf?.k_cal_100g || '???')} kCal pour 100g</IonLabel>
            </IonChip>
          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  )
} )

export default Ingredient
