import axios from 'axios'
import { makeObservable, observable } from 'mobx'
import { stores } from '../contexts/storesContext'

const TIMEOUT_CONFIG = 10000

const endpoints = {
  login: () => `${process.env.REACT_APP_RECIPES_API_ROOT}/jwt-auth/v1/token`,
  recipes: () => `${process.env.REACT_APP_RECIPES_API_ROOT}/wp/v2/recipes`,
  ingredients: () => `${process.env.REACT_APP_RECIPES_API_ROOT}/wp/v2/ingredients`,
  tools: () => `${process.env.REACT_APP_RECIPES_API_ROOT}/wp/v2/tools`,
}

let refreshSubscribers = [];

class API {

  @observable queues = {}
  authToken = null

  constructor() {
    makeObservable(this)

    this._axios = axios.create({
      timeout: TIMEOUT_CONFIG,
    })

    this._axios.interceptors.request.use(config => {
      if(this.authToken){
        config.headers.Authorization = `Bearer ${this.authToken}`
      }
      return config
    })

    this._axios.interceptors.response.use(
      response => response,
      async error => {
        const ogRequest = error?.config;
        if (error?.response?.status === 401 && !ogRequest?._retry) {
          ogRequest._retry = true;
          stores.accountStore.signOut();
          return new Promise((resolve, reject) => {
            refreshSubscribers.push(async () => {
              console.log('Refiring request that waited for new token.');
              ogRequest.headers.Authorization = `Bearer ${this.authToken}`;
              try {
                const response = await axios(ogRequest);
                resolve(response);
              } catch (e) {
                reject(e);
              }
            });
          });
        }

        // Log API error to kibana
        console.log(
          'non-token related API error detected or refetch failed',
          error.toString(),
          error?.response?.data,
          JSON.stringify(error.config),
        )
        return new Promise((_, reject) => reject(error))
      },
    );
  }

  get axios() {
    return this._axios
  }

  get rawAxios() {
    return axios.create({
      timeout: TIMEOUT_CONFIG,
    })
  }

  get endpoints() {
    return endpoints
  }

  setToken(token){
    this.authToken = token
    if(token){
      refreshSubscribers.forEach(callback => callback())
      refreshSubscribers = []
    }
  }

  enqueue(queueName, func) {
    const storedPromise = { func }
    storedPromise.promise = new Promise((resolve, reject) => {
      storedPromise.resolve = resolve
      storedPromise.reject = reject
    })
    if (this.queues[queueName] === undefined) {
      this.queues[queueName] = []
    }
    this.queues[queueName].push(storedPromise)
    if (this.queues[queueName].length === 1) this.processQueue(queueName)
    return storedPromise.promise
  }

  async processQueue(queueName) {
    if (!this.queues[queueName].length) return

    const storedPromise = this.queues[queueName][0]
    try {
      const result = await storedPromise.func()
      storedPromise.resolve(result)
    } catch (error) {
      storedPromise.reject(error)
    }
    this.queues[queueName].shift()
    this.processQueue(queueName)
  }

}

export default new API()