import { IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, 
         IonLabel, IonCardTitle, IonImg, IonToggle } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '../../hooks/useStores'
import styles from './Methods.module.css'

const Methods = observer( ({id})=> {
  const { recipesStore } = useStores()
  const recipe = recipesStore.getRecipe(id)

  const toggleMethodCheck = (recipe, part, block) => {
    recipesStore.toggleMethodCheck(recipe, part, block);
  }
  const toggleMethodPart = (recipe, part) => {
    recipesStore.toggleMethodPart(recipe, part);
  }

  return (
    <>
      {recipe?.acf?.parts?.map?.((part, partIndex) => {
        if(!part?.method?.length)
          return null
        return (
          <IonCard key={partIndex}>
            <IonCardHeader>
              <IonToggle
                color="primary" 
                class="ion-float-right" 
                style={{transform:"scale(0.6) translateY(40px)"}}
                checked={!recipesStore.isMethodPartHidden(id, partIndex)}
                onClick={toggleMethodPart.bind(this, id, partIndex)}
              />
              <IonCardSubtitle>
                <IonLabel>Préparation</IonLabel>
              </IonCardSubtitle>
              <IonCardTitle>
                <IonLabel className={styles.partTitle}>{part.title}</IonLabel>
              </IonCardTitle>
            </IonCardHeader>

            {!recipesStore.isMethodPartHidden(id, partIndex) && part?.method?.map?.((block, blockIndex) => {
              switch(block?.acf_fc_layout){
                case 'paragraph':
                  return (
                    <IonCardContent 
                      key={blockIndex} 
                      className={`${styles.paragraph} ${recipesStore.isMethodChecked(id, partIndex, blockIndex)?styles.checked:''}`} 
                      onClick={toggleMethodCheck.bind(this, id, partIndex, blockIndex)}>
                      {block?.text}
                    </IonCardContent>
                  )
                case 'picture':
                  return (
                    <IonImg 
                      className={`${styles.picture} ${recipesStore.isMethodChecked(id, partIndex, blockIndex)?styles.checked:''}`} 
                      onClick={toggleMethodCheck.bind(this, id, partIndex, blockIndex)}
                      key={blockIndex} 
                      src={block.image}/>
                  )
                default:
                  return null
              }
            })}      
          </IonCard>
        )
      })}
    </>
  )
} )

export default Methods
