import { Fragment } from 'react'
import { IonImg, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, 
         IonLabel, IonCardTitle, IonList, IonItemSliding, IonItem, 
         IonItemOptions, IonItemOption, IonAvatar, IonToggle, IonSegment, IonSegmentButton } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import useStores from '../../hooks/useStores'
import styles from './ToolsCard.module.css'
import { useHistory } from 'react-router'

const ToolsCard = observer( ({id})=> {
  const { recipesStore, toolsStore } = useStores()

  const recipe = recipesStore.getRecipe(id)

  const history = useHistory()

  const toggleTools = async function(e){
    recipesStore.toggleTools()
  }
  const changeToolsListData = e => {
    recipesStore.setToolsListData(e.detail.value)
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonToggle 
          color="primary" 
          class="ion-float-right" 
          style={{transform:"scale(0.6)"}}
          checked={recipesStore.displayTools}
          onClick={toggleTools}
        />
        <IonCardTitle>
          <IonLabel>Ustensiles</IonLabel>
        </IonCardTitle>
      </IonCardHeader>

      {recipesStore.displayTools && 
        <>
          {recipe?.acf?.parts.map((part, index) => {
            if(!part.tools.length) 
              return null
            return (
              <Fragment key={index}>
                <IonCardHeader>
                  <IonCardSubtitle>{part.title}</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonList>
                    {part?.tools?.map((toolItem, index) => {
                      let tool = toolsStore.getTool(toolItem.tool)
                      let priceTool = (tool?.acf?.price || 0) / (tool?.acf?.number_of_uses || 1) * (toolItem?.quantity || 0)
                      return (
                        <IonItemSliding key={index} className={styles.tool}>

                          <IonItemOptions side="start">
                            <IonItemOption color="secondary" onClick={()=>{ history.push(`/tools/${tool.id}`) }}>Détails</IonItemOption>
                          </IonItemOptions>

                          <IonItem>
                            <IonAvatar slot="start">
                              <IonImg src={tool?.acf?.cover_image} style={{background:"white"}}/>
                            </IonAvatar>
                            <IonLabel>{tool?.title}</IonLabel>
                            <IonLabel slot="end" className="ion-text-right" style={{flex:"0 0 60px"}}>
                              {recipesStore.toolsListData === 'qty'? toolItem?.quantity
                              :recipesStore.toolsListData === 'price'? priceTool.toFixed(2)+" €"
                              :""}
                            </IonLabel>
                          </IonItem>

                          <IonItemOptions side="end">
                            <IonItemOption color="primary">À acheter</IonItemOption>
                          </IonItemOptions>

                        </IonItemSliding>
                      )
                    })}
                  </IonList>
                </IonCardContent>
              </Fragment>
            )
          })}

          <IonCardContent>
            <IonList>
              <IonItemSliding className={styles.tool}>
                <IonItem>
                  <IonSegment value={recipesStore.toolsListData} onIonChange={changeToolsListData}>
                    <IonSegmentButton value="qty">Quantité</IonSegmentButton>
                    <IonSegmentButton value="price">Prix</IonSegmentButton>
                  </IonSegment>
                </IonItem>
              </IonItemSliding>
            </IonList>
          </IonCardContent>
        </>
      }
    </IonCard>
  )
} )

export default ToolsCard
